import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../../layouts/SimplePage';
import FirstScreenSection from '../../components/FirstScreenSection';
import GradesSection from '../../components/GradesSection';
import Calculator from '../../components/Calculator';
import Testimonials from '../../components/Testimonials';
import OurFeaturesSection from '../../components/OurFeaturesSection';
import { SeoTextSection, SeoTextHeading, SeoTextContent } from '../../components/SeoText';
import CTA from '../../components/CTA';


const SociologyWritingServicePage = () => (
  <Layout
    pageWrapperClassName="sociology-writing-service-page"
    headerClassName="header_white-bg"
  >

    <Helmet
      title="Sociology Essay Writing Service - Sociology Essay Writer"
      meta={[
        {
          name: 'description',
          content: 'TakeAwayEssay.com 📝 is a Sociology Essay Writing Service dedicated to providing top-notch quality services. Highly qualified Sociology Essay Writer guarantee customer satisfaction, 24/7 support, and positive reviews from previous clients.',
        },

        {
          property: 'og:title',
          content: 'Custom Sociology Essay Writing Service for Your Academic Success | TakeAwayEssay.com',
        },
        {
          property: 'og:description',
          content: 'We provide custom sociology essay writing service at affordable prices with premium quality. Order your custom essay from a reputable writing company now to improve your studies. We offer high-quality affordable services and provide 100% unique papers and we are available 24/7.',
        },
        { property: 'og:site_name', content: 'Takeawayessay.com' },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: 'https://takeawayessay.com/open-graph-img.jpg' },
      ]}
      link={[
        { rel: 'canonical', href: 'https://takeawayessay.com/sociology-writing-service.html' },
      ]}
    />

    <FirstScreenSection containerClassName="first-screen-section--seo">
      <h2 className="first-screen-section__title first-screen-section__title_seo">
        Feel like your need for
        <br className="first-screen-section__title__br" />
        {' '}
        quality writing remains unsatisfied?
      </h2>
      <p className="first-screen-section__heading first-screen-section__heading_seo">
        We add a new layer to Maslow’s pyramid:
        <br className="first-screen-section__heading__br" />
        {' '}
        getting sociology essays written fast and easy
      </p>
    </FirstScreenSection>

    <GradesSection />
    <Calculator />
    <Testimonials nameOfPageArray="home" />
    <OurFeaturesSection />

    <SeoTextSection>
      <SeoTextHeading>
        Sociology essay writer: <br /> sociology essay writing service
      </SeoTextHeading>

      <SeoTextContent>
        <div className="seo-text-section__text__css-half-column">
          <p>
            Student life at college can be one of the most enjoyable stages of your life. However,
            if you major in a discipline like sociology, your student years can be stressful too.
            Why so? Because of the many papers and essays that you have to write during a term
            (quarter or semester)!
          </p>
          <p>
            Because of many assignments, the social life of an average sociology student suffers. If
            this is your case, we recommend using our custom sociology essay writing service. How
            can our service improve the quality of your student life? Read on.
          </p>
          <p>
            Well, it is simple. We write any assignment for you to use it as an example for your
            studies. We don’t do it for free, of course. But our prices are quite affordable. We
            know that most students do not have much money to spend on writing services. This is why
            we try to keep our prices as low as possible.
          </p>
          <p>
            <strong>
              We are a company with a solid reputation. Our sociology essay writing service has been
              helping students to succeed since 2018.{' '}
            </strong>
            We have the experience and expertise to write any type of assignment. We can do the
            necessary research to write an advanced essay. Well, we can even write your final
            dissertation for a graduate degree in sociology!
          </p>
          <h2 className="h3">Buy Sociology Essays from a Reputable Online Company</h2>
          <p>
            We offer the best sociology essay writing service on the market. You can read the
            reviews of our service on different students’ forums. The students that have paid for
            our custom essays are satisfied with the result. We are professional and reliable.
          </p>
          <p>
            You can buy sociology essay on our website. Placing an order is a very simple process.
            Fill in an electronic form with all the data about the paper you want us to write for
            you. Don’t worry; you don’t have to disclose any private information like the name of
            your college or professor.
          </p>
          <p>
            Common information we require includes the title of the paper, the length (number of
            pages), etc. You have to specify whether your order is urgent. With all this
            information, the price of the work will be displayed on your order form.
          </p>
          <p>
            Once we get your payment, a professional writer is assigned to handle your order. This
            helper will work fast to meet the deadline you set. You will get your completed essay on
            time. Buy sociology essay online. Our work will surpass your expectations.
          </p>
          <h2 className="h3">Can you write my sociology essay to help me get a top grade?</h2>
          <p>
            This is a common question students ask. The answer is yes. Our writers spare no effort
            to deliver papers that will ensure top grades. Send us a message saying “write my
            sociology essay to get a top grade” and we will help you.
          </p>
          <p>
            You may wonder “how can you offer such a highly rated service?” Well, one of the main
            reasons is our excellent writing staff. To achieve this:
          </p>
          <ul className="dot-list">
            <li>We hire specialists in sociology that are also proficient in academic writing</li>
            <li>
              We verify the diplomas of our writers to ensure that only qualified personnel write
              our customers’ assignments
            </li>
            <li>We continuously provide feedback and training to improve our writers’ skills</li>
          </ul>
          <p>
            Send us a “do my sociology essay” message and get proper online help. Our vast
            experience guarantees you get the best essay you can buy. The market has been saturated
            with companies that offer cheap academic papers for sale. We sincerely recommend
            avoiding such services. Don’t make a mistake.
          </p>
          <p>
            Like with any purchase, you get what you pay for. Using dubious writing services can be
            disastrous. For instance, poor-quality papers aren’t checked for plagiarism.
          </p>
          <p>
            Contrastingly, we instruct our writers to abide by our non-plagiarism policy. Use our
            premium custom sociology essay writing service and avoid problems. Our service is legit
            and confidential. Nobody will know you bought a paper from us.
          </p>
          <h2 className="h3">Pay for sociology essay and enjoy a more relaxed student life</h2>
          <p>
            We often see students coming to us in despair. They say something like “Help me, please!
            My academic future depends on this essay.” The solution is simple. Buy custom sociology
            essays from us and boost the quality of your student life.
          </p>
          <p>
            With our help, you will have more free time to regain your social life. You will be able
            to hang out with your friends and visit your family while a professional writer helps
            you with your papers. Place an order now!
          </p>
        </div>
      </SeoTextContent>
    </SeoTextSection>

    <CTA btnText="Hire our writers">
      Statistically, an academic paper purhased
      <br />
      {' '}
      on our website affects grades positively
    </CTA>

  </Layout>
);

export default SociologyWritingServicePage;
